import React from "react";
import ProjectItem from "./project";

const Portfolio = () => {
  const projectItems = [
    {
      id: 1,
      category: "Residential",
      title: "Complete Cost Estimation for 5-Bedroom House",
      imageUrl: "https://images.unsplash.com/photo-1708996844012-ce2f13280d2b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      likes: 210,
    },
    {
      id: 2,
      category: "Commercial",
      title: "Office Building Cost Analysis",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMZe-FOjP9omHqx14EUx9iIJDSD5nje4FDQA&s",
      likes: 145,
    },
    {
      id: 3,
      category: "Infrastructure",
      title: "Bridge Construction Cost Estimation",
      imageUrl: "https://i0.wp.com/reconasia.csis.org/wp-content/uploads/2018/12/19_projects_-_header_image-scaled-e1619634491512.jpeg?fit=2000%2C1335&ssl=1",
      likes: 89,
    },
    {
      id:4,
      category:"site work estimates",
      imageUrl:'https://worldestimating.com/wp-content/uploads/2020/06/sitework-estimating-services.jpg',
      likes:50,
    }
    ,{
      id:5,
      category:"quantit take-off",
      imageUrl:'https://f.hubspotusercontent10.net/hubfs/2004318/quantity%20takeoff.jpg',
      likes:500,
    }
    ,{
      id:6,
      category:"carpenter estimates",
      imageUrl:'https://images.unsplash.com/photo-1590880795696-20c7dfadacde?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      likes:100,
    }
  
  ];

  return (
    <section className="bg-slate-950 py-16 px-4 sm:px-6 lg:px-8 border-b-2 border-slate-800">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-yellow-500 text-sm font-bold mb-4 text-center">Visit my portfolio and leave your feedback</h2>
        <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold text-center text-white mb-12">
          Our Cost Estimation Projects
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {projectItems.map((item) => (
            <ProjectItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
