import React, { useState } from 'react';

// Reusable Hamburger Icon Component
const HamburgerIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
  </svg>
);

// Navigation Links Component
const NavLinks = ({ isOpen }) => (
  <ul className={`flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-10 text-sm uppercase tracking-widest ${isOpen ? 'flex' : 'hidden'} sm:flex  `}>
    <li className="hover:text-yellow-300">
      <a href="#home">Home</a>
    </li>
    <li className="hover:text-yellow-300">
      <a href="#services">Services</a>
    </li>
    <li className="hover:text-yellow-300">
      <a href="#portfolio">Portfolio</a>
    </li>
    <li className="hover:text-yellow-300">
      <a href="#contact">Contact</a>
    </li>
  </ul>
);

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <nav className="w-full bg-slate-950 text-yellow-500 shadow-lg ">
      <div className="max-w-7xl mx-auto p-4 flex flex-col sm:flex-row justify-between items-center">
        <div className="flex items-center space-x-4 mb-4 sm:mb-0">
          <img
            src={require('../assets/img.png')}
            alt="Profile"
            className="w-12 h-12 rounded-full object-cover"
          />
          <div className="text-xl sm:text-2xl font-bold">
            Abdul Basit
          </div>
        </div>
        
        {/* Hamburger Button for Small Screens */}
        <button 
          onClick={toggleMenu} 
          className="sm:hidden text-yellow-500 focus:outline-none"
          aria-label="Toggle navigation menu"
        >
          <HamburgerIcon />
        </button>

        {/* Navigation Links */}
        <NavLinks isOpen={isOpen} />
      </div>
    </nav>
  );
};

export default Nav;
