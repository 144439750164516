import React from "react";
import '../index.css';
import { useInView } from 'react-intersection-observer';

const ContactSection = () => {
  const { ref: leftRef, inView: leftInView } = useInView({
    triggerOnce: false,  // Change to false to animate every time
    threshold: 0.1,
  });

  const { ref: rightRef, inView: rightInView } = useInView({
    triggerOnce: false,  // Change to false to animate every time
    threshold: 0.1,
  });

  return (
    <section className="bg-slate-950 py-16 px-4 sm:px-6 lg:px-8">
      <h2 className="text-yellow-500 text-sm font-bold mb-4 text-center">contact</h2>
      <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold text-center text-white mb-12">
        Contact Form
      </h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Side - Contact Card */}
        <div
          ref={leftRef}
          className={`bg-slate-900 p-8 rounded-lg shadow-lg transition-transform duration-700 ease-in-out ${
            leftInView ? 'transform translate-y-0 opacity-100' : 'transform translate-y-10 opacity-0'
          }`}
        >
          <img
            src="https://images.unsplash.com/photo-1561570541-aaba21a3ecf0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGhhbmQlMjBzaGFrZXxlbnwwfHwwfHx8MA%3D%3D"
            alt="Connect"
            className="w-full h-48 object-cover rounded-lg mb-6"
          />
          <h3 className="text-2xl font-semibold text-white mb-2">Connect with me</h3>
          <p className="text-gray-400 mb-4">
            I am available for business. Connect via email below.
          </p>
          <p className="text-gray-400 mb-4">
            Email: <span className="text-yellow-500">jhon@buildestimation.com</span>
          </p>
          <div className="flex space-x-4">
            <a href="#services" className="text-gray-400 hover:text-yellow-500">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#services" className="text-gray-400 hover:text-yellow-500">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        {/* Right Side - Contact Form */}
        <div
          ref={rightRef}
          className={`bg-slate-900 p-8 rounded-lg shadow-lg transition-transform duration-700 ease-in-out ${
            rightInView ? 'transform translate-y-0 opacity-100' : 'transform translate-y-10 opacity-0'
          }`}
        >
          <h2 className="text-3xl font-bold text-white mb-6">Contact With Me</h2>
          <form oncli>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
              <input
                type="text"
                placeholder="Your Name"
                className="bg-slate-700 p-4 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="bg-slate-700 p-4 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>
            <div className="mb-6">
              <input
                type="email"
                placeholder="Email"
                className="bg-slate-700 p-4 rounded-lg w-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                placeholder="Subject"
                className="bg-slate-700 p-4 rounded-lg w-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>
            <div className="mb-6">
              <textarea
                placeholder="Your Message"
                rows="4"
                className="bg-slate-700 p-4 rounded-lg w-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-yellow-500 text-white py-3 px-6 rounded-lg w-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
