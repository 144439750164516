import React from "react";
import '../index.css'
import logo from '../assets/logogreen.jpg'


const Footer = () => {
  return (
    <footer className="bg-slate-900 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Left Section - Logo and Description */}
        <div className="flex flex-col items-center md:items-start">
          <img
            src={logo}// Replace with your logo path
            alt="Build Estimation Logo"
            className="w-16 h-16 mb-4"
          />
          <p className="text-gray-400 text-center md:text-left">
            Providing accurate and reliable cost estimation services for the construction industry. 
          </p>
        </div>

        {/* Middle Section - Social Media Links */}
        <div className="flex justify-center items-center space-x-6">
          <a href="https://facebook.com" className="text-gray-400 hover:text-yellow-500">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#services" className="text-gray-400 hover:text-yellow-500">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#services" className="text-gray-400 hover:text-yellow-500">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="#services" className="text-gray-400 hover:text-yellow-500">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>

        {/* Right Section - Website Link */}
        <div className="flex flex-col items-center md:items-end">
          <p className="text-gray-400 mb-2">
            For further information, visit our website:
          </p>
          <a
            href="https://buildestimation.us"
            target="_blank"
            rel="noopener noreferrer"
            className="text-yellow-500 hover:text-yellow-300"
          >
            www.buildestimation.us
        </a>
        </div>
      </div>

      {/* Bottom Section - Copyright */}
      <div className="mt-8 text-center text-gray-500 text-sm">
        &copy; {new Date().getFullYear()} Build Estimation. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
