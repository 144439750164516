import React from "react";
import { useInView } from "react-intersection-observer";

const ProjectItem = ({ item }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1, 
  });

  return (
    <div
      ref={ref}
      className={`bg-slate-900 p-6 rounded-lg shadow-lg transition-transform duration-700 ease-in-out ${
        inView ? "transform translate-y-0 opacity-100" : "transform translate-y-10 opacity-0"
      }`}
    >
      <div className="mb-4">
        <img
          src={item.imageUrl}
          alt={item.title}
          className="w-full h-48 object-cover rounded-lg"
        />
      </div>
      <p className="text-yellow-500 text-sm font-semibold uppercase mb-2">
        {item.category}
      </p>
      <h4 className="text-xl font-semibold text-white mb-4">
        {item.title}
      </h4>
      <div className="flex items-center text-gray-400">
        <i className="fas fa-heart mr-2"></i>
        <span>{item.likes}</span>
      </div>
    </div>
  );
};

export default ProjectItem;
