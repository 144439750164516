import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import "../index.css";
import img from "../assets/img.png";

const HeroSection = () => {
  const [title, setTitle] = useState("a Founder");
  const [textColor, setTextColor] = useState("text-yellow-500");

  // Intersection observers for different sections
  const { ref: containerRef, inView: containerInView } = useInView({
    threshold: 0.1,
  });

  const { ref: textRef, inView: textInView } = useInView({
    threshold: 0.1,
    delay: 500, // Delay to start animation after container
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    threshold: 0.1,
    delay: 1000, // Delay to start animation after text
  });

  // Rotating title and color effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTitle((prevTitle) =>
        prevTitle === "a Founder" ? "an Estimator" : "a Founder"
      );
      setTextColor((prevColor) =>
        prevColor === "text-yellow-500" ? "text-blue-500" : "text-yellow-500"
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      ref={containerRef}
      className={`bg-slate-950 min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 border-b-2 border-slate-800 ${containerInView ? 'animate-container' : ''}`}
    >
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="lg:w-1/2 text-center lg:text-left">
          <h1
            ref={textRef}
            className={`text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4 transition-opacity duration-1000 ease-in-out ${textInView ? 'opacity-100' : 'opacity-0'}`}
          >
            Hi, I'm <span className="text-yellow-500">Abdul Basit</span>
          </h1>
          <h2
            className={`text-2xl sm:text-3xl md:text-4xl font-light mb-6 transition duration-500 ease-in-out transform ${textColor} ${textInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            {title}
          </h2>
          <p
            className={`text-gray-400 text-lg sm:text-xl md:text-2xl leading-relaxed mb-8 transition-opacity duration-1000 ease-in-out ${textInView ? 'opacity-100' : 'opacity-0'}`}
          >
            Abdul Basit, the founder of Buildestimation, is a passionate
            entrepreneur with a knack for creating innovative solutions that
            simplify complex construction tasks. He consistently contributes to
            the industry through his expertise in cost estimation and by sharing
            valuable insights through blogs and professional platforms.
          </p>

          <div className="flex justify-center lg:justify-start space-x-4">
            <div className="text-white text-sm">
              <p className={`mb-2 text-yellow-600 transition-opacity duration-1000 ease-in-out ${textInView ? 'opacity-100' : 'opacity-0'}`}>
                Find me on
              </p>
              <div className="flex space-x-2">
                <a
                  href="https://facebook.com"
                  className="bg-gray-800 p-2 rounded-full hover:bg-yellow-500 transition-colors"
                >
                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiXN9xSEe8unzPBEQOeAKXd9Q55efGHGB9BA&s'
                    alt=""
                    className="h-5 w-5 rounded-full object-contain"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/abdulbasit-buildestimation"
                  className="bg-gray-800 p-2 rounded-full hover:bg-yellow-500 transition-colors"
                >
                  <img
                    src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png'
                    alt=""
                    className="h-5 w-5 rounded-full object-contain"
                  />
                </a>
                <a
                  href="mailto:info@buildestimation.us" 
                 
                  className="bg-gray-800 p-2 rounded-full hover:bg-yellow-500 transition-colors"
                >
                  <img
                    src='https://seeklogo.com/images/G/gmail-new-2020-logo-32DBE11BB4-seeklogo.com.png'
                    alt=""
                    className="h-5 w-5 rounded-full object-contain"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={imageRef}
          className={`inline-block mt-8 lg:mt-0 shadow-lg shadow-slate-700 transition-transform duration-1000 ease-in-out ${imageInView ? 'transform scale-100' : 'transform scale-90'}`}
        >
          <img
            src={img}
            alt="Profile"
            className={`w-full h-full max-w-sm mx-auto rounded-lg ${imageInView ? 'opacity-100' : 'opacity-0'}`}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
