
import './App.css';
import Footer from './components/footer';
import HeroSection from './components/hero';
import Services from './components/services';
import Portfolio from './components/portfolio'
import './index.css'
import ContactSection from './components/form';
import Nav from './components/nav';

function App() {
  return (
   <div className='bg-slate-900'>  
     <Nav/>
   <div  id="home">
    <HeroSection/>
   </div>
   <div  id="services">
  <Services/>
  </div>
  <div id="portfolio">
  <Portfolio/>
  </div>
  <div id="contact">

  <ContactSection/>
  </div>
  <Footer/>
   </div>
  );
}

export default App;
