import React, { memo } from "react";
import '../index.css'; 
import { FaCalculator, FaRulerCombined, FaUserTie, FaProjectDiagram } from 'react-icons/fa';
import { HiArrowRight } from 'react-icons/hi';
import { useInView } from 'react-intersection-observer';

const servicesData = [
  {
    icon: FaCalculator,
    title: "Cost Estimation",
    description: "We provide accurate and detailed cost estimation services for construction projects, ensuring that you stay within budget and avoid unexpected expenses. Our team has extensive experience and uses the latest tools to deliver reliable estimates.",
  },
  {
    icon: FaRulerCombined,
    title: "Quantity Take-Off",
    description: "Our quantity take-off services ensure that every material is accounted for, providing you with precise measurements and reducing waste. We help you optimize material usage and plan effectively.",
  },
  {
    icon: FaUserTie,
    title: "Consultancy",
    description: "We offer expert consultancy services to help you make informed decisions about your construction projects. Our insights and recommendations are based on years of industry experience.",
  },
  {
    icon: FaProjectDiagram,
    title: "Project Management",
    description: "Our project management services ensure that your construction projects are completed on time, within budget, and to the highest standards. We manage every aspect of the project from start to finish.",
  },
];

const ServiceCard = memo(({ icon: Icon, title, description, index }) => (
  <div 
    className={`bg-slate-900 p-6 rounded-lg shadow-lg staggered-animation`} 
    style={{ animationDelay: `${index * 0.2}s` }} // Delay based on index
  >
    <div className="text-yellow-500 text-4xl mb-4">
      <Icon size={30} color="#FACC15" /> {/* Set icon color to match the arrow color */}
    </div>
    <h4 className="text-2xl font-semibold text-white mb-4">
      {title}
    </h4>
    <p className="text-gray-400">
      {description}
    </p>
    <a href="https://buildestimation.us" className="text-yellow-500 flex items-center mt-4">
      <HiArrowRight size={24} color="#FACC15" className="ml-2" /> {/* Arrow with matching color */}
    </a>
  </div>
));

const Services = () => {
  const { ref: sectionRef, inView: sectionInView,  } = useInView({
    triggerOnce: false, // Trigger animation every time
    threshold: 0.1,
  });

  return (
    <section 
      ref={sectionRef} 
      className={`bg-slate-950 py-16 px-4 sm:px-6 lg:px-8 ${sectionInView ? 'animate-slide-up' : 'opacity-0'}`}
    >
      <div className="max-w-7xl mx-auto">
        <h2 className={`text-yellow-500 text-sm font-bold mb-4 ${sectionInView ? 'animate-text' : 'opacity-0'}`}>SERVICES</h2>
        <h3 className={`text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-12 ${sectionInView ? 'animate-text' : 'opacity-0'}`}>
          What We Provide
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {servicesData.map(({ icon, title, description }, index) => (
            <ServiceCard 
              key={index} 
              icon={icon} 
              title={title} 
              description={description}
              index={index} // Pass index for delay
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
